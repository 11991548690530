@import './variables.scss';

.landing-page {
    background-image: url('landing-page-cover-image-medium.jpg');
    width: 100vw;
    height: 100vh;
    background-position-x: left;
    background-position-y: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;

    .container {
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 40vh;
        max-width: 50vw;
    
        @media all and (max-width: $breakpoint-medium) {
            margin-top: 20vh;
            max-width: 60vw;
        }
    
        @media all and (max-width: $breakpoint-small) {
            margin-top: 10vh;
            max-width: 100%;
        }
    }

    h1 {
        font-size: 48px;
        color: #fff;
        font-family: 'Segoe UI';
        font-weight: 400;
    
        @media all and (max-width: $breakpoint-small) {
            font-size: 32px;
        }
    }

    h2 {
        font-size: 32px;
        color: #fff;
        font-family: 'Segoe UI';
        font-weight: 200;
        margin-top: 32px;
    
        @media all and (max-width: $breakpoint-small) {
            font-size: 18px;
            margin-top: 16px;
        }
    }

    .button {
        display: block;
        position: relative;
        float: left;
        background-color: orange;
        border-radius: 6px;
        padding: 12px;
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        margin-top: 12px;
    }
}
.headered-box {
    background-color: #fdfdfd;
    border: 1px solid #eaeaea;
    border-radius: 4px;

    > header {
        display: flex;
        align-items: center;

        height: 48px;

        box-sizing: border-box;
        border-bottom: 1px solid #eaeaea;

        > h2 {
            font-size: 14px;

            margin: 0 16px;
        }
    }

    > .content {
        padding: 16px;
    }
}
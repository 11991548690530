@import '../variables.scss';

.recipe {
    margin: -32px; /* Span whole page */
}

.recipe-header {
    position: relative;

    width: 100%;
    height: 240px;

    display: flex;
    overflow: hidden;
}

.recipe-background-image {
    background-size: cover;
    background-position: center;
    filter: blur(7px);

    width: 120%;
    height: 120%;
    position: absolute;
    z-index: -1;
    margin: -16px;
}

.recipe-background-image .overlay {
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.recipe-image {
    flex: 0 0 160px;
    height: 160px;
    margin: 32px;

    background-size: cover;
    background-position: center;
    border-radius: 4px;

    @media all and (max-width: $breakpoint-small) {
        flex: 0 0 0;
        margin: 0 0 0 32px;
    }
}

.recipe-summary {
    color: #fff;

    flex: 1 1 auto;
    margin: 32px 32px 32px 0;

    overflow: hidden;

    h1 {
        margin: 0;

        @media all and (max-width: $breakpoint-small) {
            font-size: 24px;
        }
    }

    p {
        margin: 16px 0 0 0;

        @media all and (max-width: $breakpoint-small) {
            font-size: 14px;
        }
    }
}

.recipe-large-content {
    display: flex;

    margin: 32px;

    @media all and (max-width: $breakpoint-small) {
        display: none;
    }
}

.recipe-large-ingredients {
    flex: 0 0 240px;
}

.recipe-large-instructions {
    flex: 1 1 auto;
    margin-left: 16px;
}

.recipe-small-content {
    display: none;

    @media all and (max-width: $breakpoint-small) {
        display: block;
    }
}

.ingredients-tab {
    margin: 8px;
}

.instructions-tab {
    margin: 8px;
}

.tabpanel {
  margin: 8px;
}

h3 {
    font-size: 14px;
    font-weight: 600;
}

.ingredients-list {
    font-weight: 600;

    > li {
        line-height: 2;
    }
}

.instructions-list {

    > li {
        margin-bottom: 12px;
    }
}
@import '../variables.scss';

.app-title {
    font-weight: 600;
    line-height: 45px;
    margin-left: 32px;
    overflow: hidden;

    @media all and (max-width: $breakpoint-small) {
        display: none;
    }
}

.pivot {
    margin-left: 32px;
}

nav .profile-button {
    margin-left: auto;
    margin-right: 32px;
    background: transparent;

    @media all and (max-width: $breakpoint-small) {
        display: none;
    }
}

.profile-button-small {
    margin-left: auto;
    margin-right: 21px;
    background: transparent;

    margin-top: auto;
    margin-bottom: auto;

    @media all and (min-width: $breakpoint-small) {
        display: none;
    }
}
@import "../variables.scss";

.recipes-page-container {
    margin: 32px;
}

@media (max-width: $breakpoint-medium) {
    .recipes-page-container {
        margin: 0px;
    }
}
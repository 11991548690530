@import '../variables.scss';

$card-small-width: 100%;
$card-small-height: 80px;

$card-medium-width: 200px;
$card-medium-height: 260px;

$card-large-width: 240px;
$card-large-height: 300px;

.card {
    width: $card-large-width;
    height: $card-large-height;

    margin: 12px 12px 0 0;
    float: left;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    border-radius: 4px;
    box-shadow: 0 2px 8px 2px rgba(105, 105, 105, 0.2);
    
    transition: 0.25s;
    user-select: none;

    /* Add margin to end of page */
    &:last-child {
        margin-bottom: 32px;
    }

    @media all and (max-width: $breakpoint-medium) {
        width: $card-medium-width;
        height: $card-medium-height;
    }

    @media all and (max-width: $breakpoint-small) {
        width: $card-small-width;
        height: $card-small-height;
        flex-direction: row;
    }

    &:hover {
        box-shadow: 0 2px 8px 2px rgba(0,0,0,0.2);
    }

    img {
        object-fit: cover;
        flex-shrink: 0;

        width: $card-large-width;
        height: $card-large-width;

        transition: 0.25s;

        @media all and (max-width: $breakpoint-medium) {
            width: $card-medium-width;
            height: $card-medium-width;
        }

        @media all and (max-width: $breakpoint-small) {
            width: $card-small-height;
            height: $card-small-height;
        }
    }

    .content {
        margin: 8px 16px;
        overflow: hidden;

        @media all and (max-width: $breakpoint-small) {
            margin: 16px;
        }

        h4 {
            white-space: nowrap;
            font-weight: bold;
            font-size: 14px;

            @media all and (max-width: $breakpoint-small) {
                /* Allow 2 lines */
                white-space: normal;
                line-height: 16px;
                max-height: 32px;
                overflow: hidden;
            }
        }

        p {
            margin-top: 4px;

            font-size: 12px;
            color: #777;
        }
    }
}